<template>
  <div class="scrollable">
    <div class="">
      <div class="__loading" v-if="loadingTemplate">
        <div class="div" style="height: 80vh" v-loading="loadingTemplate"></div>
      </div>
      <div id="__messsages" v-if="!loadingTemplate">
        <div class="messages__navigation contains-main-voice-buttons">
          <div class="left-side-tab">
            <button
              class="__reporting__nav"
              @click="
                $router.push('/voice/messages').catch((err) => {
                  if (err.name !== 'NavigationDuplicated') throw err;
                })
              ">
              {{ $t("dashboard.voice_templates.template") }}
            </button>
            <button
              @click="
                $router.push('/voice/scheduled-message').catch((err) => {
                  if (err.name !== 'NavigationDuplicated') throw err;
                })
              "
              class="is-active-tab">
              {{ $t("dashboard.schedule_voice.scheduled_voice") }}
            </button>
          </div>
          <div class="right-side-tab">
            <button
              type="button"
              class="is-create-button"
              v-if="
                Array.isArray(messageTemplates) && messageTemplates.length > 0
              "
              @click="openMessageModal('sendMessage')">
              <img class="mr-1" src="../../../assets/send__light.svg" alt="" />
              {{ $t("dashboard.schedule_voice.table.schedule_voice_message") }}
            </button>
          </div>
        </div>
        <div class="__main_messages">
          <!-- MESSAGES EMPTY STATE -->
          <div class="__empty__state">
            <div
              id="__messages__card"
              class="mnotify-card"
              v-if="
                Array.isArray(messageTemplates) && messageTemplates.length == 0
              ">
              <div id="side-indicators-content" class="mnotify-card-header">
                <h3 class="mnotify-card-header-text py-0 my-0">
                  {{ $t("dashboard.schedule_voice.title") }}
                </h3>
              </div>
              <div class="mnotify-card-body">
                <div class="__nodata_messages">
                  <div class="_no_message_header table_wrapper">
                    <div class="__table">
                      <table class="__mnotify__table">
                        <thead class="__mnotify__table__header">
                          <tr>
                            <th
                              class="__mnotify__table__header_first tb__messages">
                              {{ $t("dashboard.schedule_voice.table.name") }}
                            </th>
                            <th class="tb__date_time">
                              {{
                                $t("dashboard.schedule_voice.table.date_time)")
                              }}
                            </th>
                            <th class="tb__action">
                              {{ $t("dashboard.schedule_voice.table.actions") }}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div class="no_message_message">
                    <h3 class="__nodata__header">
                      {{ $t("dashboard.schedule_voice.table.no_schedule") }}
                    </h3>
                    <!-- <button class="__create_a_new_template"  @click="openMessageModal('template')">Schedule a New Message</button> -->
                    <div class="__button_wrapper">
                      <button @click="openMessageModal('sendMessage')">
                        {{
                          $t(
                            "dashboard.schedule_voice.table.schedule_voice_message"
                          )
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- MESSAGES EMPTY STATE -->
          <!-- MESSAGES -->
          <div
            class="__all__messages"
            v-if="
              Array.isArray(messageTemplates) && messageTemplates.length > 0
            ">
            <div id="__messages__card" class="mnotify-card">
              <div id="side-indicators-content" class="mnotify-card-header">
                <h3 class="mnotify-card-header-text py-0 my-0">
                  {{ $t("dashboard.schedule_voice.title") }}
                </h3>
              </div>
              <div class="mnotify-card-body">
                <div class="__nodata_messages">
                  <div class="__nodata_messages table_wrapper">
                    <el-table
                      :header-cell-style="{
                        background: 'rgba(212, 216, 226, 0.1)',
                      }"
                      :data="displayData"
                      ref="singleTable"
                      height="60vh">
                      <el-table-column
                        :label="$t('misc.table.name')"
                        width="200">
                        <template slot-scope="scope">
                          {{ scope.row.campaign_name }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.scheduled'
                          )
                        "
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{
                            moment(scope.row.date_time).format(
                              "ddd D MMM YY h:mma"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <!-- Last Sent -->
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.last_sent'
                          )
                        "
                        :width="customWidth">
                        <template slot-scope="scope">
                          <span
                            style="font-size: 12px; height: 100"
                            v-if="
                              scope.row.is_repeated == 1 &&
                              scope.row.status == 4
                            "
                            >{{
                              moment(scope.row.last_date_time).format(
                                "ddd D MMM YY h:mma"
                              )
                            }}</span
                          >
                        </template>
                      </el-table-column>
                      <!-- Recipients -->
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.recipients'
                          )
                        "
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{
                            scope.row.recipeints
                              ? scope.row.recipeints
                              : scope.row.contacts
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.type'
                          )
                        "
                        :width="customWidth"
                        style="align: center">
                        <template slot-scope="scope">
                          <p
                            style="margin: 0 auto; color: #ff8066"
                            v-if="scope.row.is_repeated == 1">
                            REPEATED
                          </p>
                          <p style="margin: 0 auto; color: #000000" v-else>
                            REGULAR
                          </p>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.repeat_period'
                          )
                        "
                        :width="customWidth"
                        style="align: center">
                        <template slot-scope="scope">
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-if="scope.row.repeated_period == 'DAILY'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.daily"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == '1_WEEK'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.week_1"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == '2_WEEK'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.week_2"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == '3_WEEK'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.week_3"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == 'MONTHLY'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.monthly"
                              )
                            }}
                          </p>
                          <p style="margin: 0 auto; color: #000000" v-else>-</p>
                        </template>
                      </el-table-column>
                      <!-- Status -->
                      <!-- Status Column -->
                      <el-table-column
                        :label="$t('misc.table.status')"
                        :width="customWidth">
                        <template slot-scope="scope">
                          <p
                            style="margin: 0 auto; color: #ef9e14"
                            v-if="scope.row.status == 0">
                            {{ $t("misc.status.pending") }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #28a745"
                            v-else-if="scope.row.status == 1">
                            {{ $t("misc.status.completed") }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #00b4d8"
                            v-else-if="scope.row.status == 2">
                            {{ $t("misc.status.in_progress") }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #f0c630"
                            v-else-if="scope.row.status == 3">
                            {{ $t("misc.status.accepted") }}
                          </p>

                          <p
                            style="margin: 0 auto; color: #28a745"
                            v-else-if="scope.row.status == 4">
                            {{ $t("misc.status.repeating") }}
                          </p>
                          <!-- ref="status_content">{{scope.row.status == 1 ? "COMPLETED" :"Ed"}}</p> -->
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('misc.table.actions')"
                        style="border: 1px solid red"
                        :width="customWidth">
                        <template slot-scope="scope">
                          <button
                            v-if="scope.row.status !== 1"
                            @click="
                              opendModal({
                                message: scope.row,
                                key: scope.$index,
                                type: 'send',
                                sendType: '__scheduled',
                              })
                            "
                            class="tb_btn __send edit-button"
                            title="Edit Scheduled Message">
                            <!-- <img width="20px" height="20px"
                                                                                                                src="@/assets/Edit.svg" alt="">
                                                                                                             -->
                            <svg
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.64691 17.0279L15.4333 5.66544C15.9108 5.0527 16.0806 4.3443 15.9214 3.62299C15.7835 2.96726 15.3802 2.34377 14.7754 1.87078L13.3004 0.699064C12.0164 -0.322161 10.4246 -0.214663 9.51203 0.957057L8.52516 2.23735C8.39782 2.39752 8.42965 2.63401 8.58882 2.76301C8.58882 2.76301 11.0825 4.76246 11.1356 4.80546C11.3054 4.96671 11.4327 5.1817 11.4646 5.43969C11.5176 5.94493 11.1674 6.41792 10.6475 6.48242C10.4034 6.51467 10.1699 6.43942 10.0002 6.29967L7.3791 4.21422C7.25177 4.11855 7.06076 4.13898 6.95464 4.26797L0.725646 12.3303C0.322406 12.8355 0.184456 13.4912 0.322406 14.1255L1.11827 17.5761C1.16072 17.7589 1.31989 17.8879 1.5109 17.8879L5.01272 17.8449C5.64942 17.8341 6.24367 17.5439 6.64691 17.0279ZM11.5502 15.9533H17.2603C17.8174 15.9533 18.2705 16.4123 18.2705 16.9766C18.2705 17.5421 17.8174 18 17.2603 18H11.5502C10.9931 18 10.54 17.5421 10.54 16.9766C10.54 16.4123 10.9931 15.9533 11.5502 15.9533Z"
                                fill="#D4D8E2" />
                            </svg>
                          </button>
                          <button
                            class="tb_btn __send play-audio"
                            @click="
                              opendModal({
                                message: scope.row,
                                key: scope.$index,
                                type: 'play',
                              })
                            "
                            title="Play Voice Message">
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.466064 10.3014C0.466064 4.78185 4.95448 0.295593 10.4661 0.295593C15.9776 0.295593 20.4661 4.78185 20.4661 10.3014C20.4661 15.8093 15.9776 20.2956 10.4661 20.2956C4.95448 20.2956 0.466064 15.8093 0.466064 10.3014ZM14.134 11.3134C14.2402 11.2073 14.3753 11.0433 14.4043 11.0047C14.5587 10.8021 14.6359 10.5513 14.6359 10.3014C14.6359 10.0206 14.5491 9.76014 14.385 9.54788C14.3715 9.53444 14.3452 9.50578 14.3109 9.46843C14.2467 9.39855 14.1545 9.29823 14.0665 9.21021C13.2749 8.3612 11.2093 6.97191 10.1282 6.5474C9.96413 6.48083 9.54908 6.33515 9.32707 6.3255C9.11471 6.3255 8.91201 6.37374 8.71896 6.47022C8.47765 6.60529 8.2846 6.81754 8.17842 7.06839C8.11085 7.24205 8.00467 7.76303 8.00467 7.77268C7.8985 8.3419 7.84058 9.2681 7.84058 10.2908C7.84058 11.2662 7.8985 12.1528 7.98537 12.7317C7.98776 12.7341 7.99606 12.7755 8.00896 12.8398C8.04817 13.0353 8.12987 13.4427 8.21703 13.6096C8.42939 14.0148 8.84444 14.2657 9.28846 14.2657H9.32707C9.61664 14.256 10.2248 14.0052 10.2248 13.9955C11.2479 13.571 13.2653 12.2493 14.0761 11.3713L14.134 11.3134Z"
                                fill="#d4d8e2" />
                              <!--was #484A4F -->
                            </svg>
                          </button>
                          <button
                            class="tb_btn _delete delete-button"
                            @click="
                              opendModal({
                                message: scope.row,
                                key: scope.$index,
                                type: 'delete',
                              })
                            "
                            title="Delete Scheduled Message">
                            <svg
                              width="19"
                              height="21"
                              viewBox="0 0 19 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.8648 3.59995C18.255 3.59995 18.5798 3.92002 18.5798 4.32728V4.70382C18.5798 5.10118 18.255 5.43115 17.8648 5.43115H1.24234C0.851189 5.43115 0.526367 5.10118 0.526367 4.70382V4.32728C0.526367 3.92002 0.851189 3.59995 1.24234 3.59995H4.16671C4.76076 3.59995 5.27774 3.18278 5.41138 2.59418L5.56452 1.91839C5.80253 0.997833 6.58581 0.386444 7.48224 0.386444H11.624C12.5106 0.386444 13.3027 0.997833 13.5319 1.86983L13.6958 2.59319C13.8285 3.18278 14.3454 3.59995 14.9405 3.59995H17.8648ZM16.3791 17.3648C16.6845 14.5536 17.219 7.87492 17.219 7.80754C17.2385 7.60341 17.1712 7.41019 17.0376 7.25461C16.8942 7.10895 16.7127 7.02274 16.5128 7.02274H2.60103C2.40009 7.02274 2.20891 7.10895 2.07625 7.25461C1.94163 7.41019 1.8753 7.60341 1.88506 7.80754C1.88685 7.81992 1.90603 8.05518 1.9381 8.44849C2.08055 10.1957 2.47731 15.0621 2.73369 17.3648C2.91512 19.0613 4.04176 20.1275 5.67367 20.1661C6.93297 20.1949 8.2303 20.2048 9.5569 20.2048C10.8064 20.2048 12.0755 20.1949 13.3738 20.1661C15.0623 20.1374 16.188 19.09 16.3791 17.3648Z"
                                fill="#d4d8e2" />
                            </svg>
                          </button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="" style="text-align: center; margin-top: 2em">
                      <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :total="total">
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- MODAL CONTENT -->
        <!-- DELETE, UPDATE AND VIEW CONTACT MODAL  START -->
        <!-- DELETE,UPDATE AND VIEW CONTACT MODAL END -->
        <messageModal
          @saveTemplate="saveTemplate"
          @editTemplate="editTemplate"
          @deleteSuccess="handleDeleteSucccess"
          :payload="dataPayload"
          :from="from"
          ref="messageModal" />
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import messageModal from "../messages/messageModal.vue";
  import store from "@/state/store.js";
  export default {
    name: "messages",
    components: { messageModal },
    data() {
      return {
        messages: "",
        success: "",
        errors: "",
        modalType: "",
        isDelete: false,
        messageTemplates: [],
        rules: {},
        loadingTemplate: false,
        page: 1,
        pageSize: 20,
        total: 0,
        formData: {
          sederId: "",
          campaigne: "",
          content: "",
        },
        sendMessage: {},
        sendMessageRules: {},
        from: "",
        dataPayload: {
          currentModal: "",
          selectedKey: "",
          selectedMessage: {},
        },
      };
    },
    computed: {
      searching() {
        if (!this.search) {
          this.total = this.messageTemplates.length;
          return this.messageTemplates;
        }
        this.page = 1;
        return this.messageTemplates.filter(
          (data) =>
            data.title.toLowerCase().includes(this.search.toLowerCase()) ||
            data.voice_note_title
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      },
      displayData() {
        this.total = this.searching.length;
        return this.searching.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      customWidth() {
        if (screen.width < 768) {
          return "150px";
        } else {
          return "min-content";
        }
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      saveTemplate(payload) {
        this.messageTemplates.push(payload);
      },
      editTemplate(payload) {
        this.messageTemplates[payload.key] = payload.data;
        this.messageTemplates = [...this.messageTemplates];
      },
      handleDeleteSucccess(id) {
        this.messageTemplates.splice(id, 1);
      },
      opendModal({ message, key, type, sendType }) {
        if (type === "delete") {
          this.dataPayload = {
            currentModal: "delete",
            selectedKey: key,
            deleteType: "scheduled__voice",
            selectedMessage: message,
            deleteMessage: "Deleting Voice Message will remove it permenantly.",
          };
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "delete",
          });
        }
        if (type === "send") {
          let payload = {
            id: message.campaign_id,
            body: message.message,
            title: message.campaign_name,
            is_repeated: message.is_repeated,
            repeated_period: message.repeated_period,
          };
          if (message.date_time) {
            payload.date_time = message.date_time;
          }
          if (message.sender_id) {
            payload.sender_id = message.sender_id;
            payload.sender = message.sender.sender_id;
          }
          if (sendType && sendType == "__scheduled") {
            this.dataPayload = {
              currentModal: "send",
              selectedKey: key,
              selectedMessage: payload,
              messageType: "__scheduled",
            };
          } else {
            this.dataPayload = {
              currentModal: "send",
              selectedKey: key,
              selectedMessage: message,
            };
          }
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
        }
        if (type === "play") {
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "voicePlayer",
            message: message,
            key: key,
          });
        }
        return this.showModal();
      },
      getScheduledMessages() {
        this.loadingTemplate = true;
        store
          .dispatch("voice/getScheduledCampaigns")
          .then((res) => {
            this.loadingTemplate = false;
            this.messageTemplates = res.data.data;
          })
          .catch((err) => {
            this.loadingTemplate = false;
          });
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      openMessageModal(type) {
        if (type === "sendMessage") {
          this.from = "simpleVoiceMessage";
          this.$emit("SMSType", "simpleVoiceMessage");
          store.dispatch("uistate/addContactFormData", {
            title: "Send Message",
            type: "selectSenderId",
            quickVoice: true,
          });
          store.dispatch("uistate/setFromScheduled", true);
        }
        return this.showModal();
      },
    },
    mounted() {
      this.getScheduledMessages();
      // Analytics
      this.$mixpanel.track("Schduled Voice Messages Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Schduled Voice Messages Page");
      }
    },
  };
</script>
<style scoped lang="scss">
  @import url("../../../styles/messages/___index.scss");

  .__create_a_new_template {
    outline: none;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #484a4f;
    margin-top: 1em;
  }

  ._text_content {
    max-width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #575761;
  }

  .is-active-tab {
    color: #f7921c;
    border: 1px solid #f7921c !important;
  }

  .contains-main-voice-buttons {
    // background-color: springgreen;
    display: flex;
  }

  .left-side-tab {
    width: 100%;
  }

  .right-side-tab {
    display: flex;
  }

  .is-create-button {
    background-color: #f7921c;
    color: white;
    border-radius: 25px;
    // border-color: #f7921c;
  }

  .play-audio:hover > svg > path {
    fill: #9032a9;
    transition: all 0.4s ease;
  }

  .delete-button:hover > svg > path {
    fill: red;
    transition: all 0.4s ease;
  }

  .edit-button:hover > svg > path {
    fill: #f7921c;
    transition: all 0.4s ease;
  }

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }

  @media screen and (max-width: 768px) {
    .scrollable {
      height: 100vh;
    }

    .left-side-tab button {
      width: 90%;
      font-size: small;
    }

    .right-side-tab button {
      width: 100%;
      font-size: small;
    }

    .left-side-tab {
      width: 50%;
    }

    .right-side-tab {
      flex-direction: column;
    }
  }
</style>
